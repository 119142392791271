body.home {
    .page--content {
        @include section-page-content;
    }
}

section.home {
    @include section-global;
}

