@import "~datatables/media/css/jquery.dataTables.min.css";

@import "~uikit/dist/css/uikit.min.css";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import '~jquery-ui/themes/base/all.css';

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: #f9f9fc;
  min-height: 100vh;
  color: #666;
}

.uk-tooltip {
  font-size: 14px;
  font-weight: 200;
  transition: background 0.05s ease;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.mini-select {
  float: right;
  width: 80px;
}

h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
  color: #FFF;
  padding: 30px 0;
}

h3 {
  margin: 0;
  color: #090910;
  margin-left: 20px;
  line-height: 26px;
  font-weight: 700;
  font-size: 24px;
}

h3:before {
  color: #e91e63;
  content: "#";
  font-weight: 400;
  margin-left: -38px;
  position: absolute;
  font-size: 44px;
  opacity: 0.6;
}

h4 {
  margin: 0;
  color: #090910;
  line-height: 28px;
  font-weight: 700;
  font-size: 22px;
}

.introduction {
  font-size: 14px;
  line-height: 30px;
  font-weight: 200;
  color: #283b80;
}

.question {
  font-size: 17px;
  line-height: 26px;
}

.mark {
  color: #e91e63;
  font-weight: 400;
  text-transform: lowercase;
}

.answer-row {
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0;
}

.answer-row label {
  font-size: 20px;
}

.text-large {
  font-size: 17px !important;
  line-height: 24px;
  font-weight: 200;
  margin-top: 14px !important;
}

.text-regular {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.text-normal {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}

.text-bold {
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
}

.text-correct {
  color: green;
}

.text-incorrect {
  color: #FF0000;
}

.uk-dropdown {
  padding: 5px;
}

.uk-dropdown li {
  padding: 3px;
  padding-left: 10px;
}

.uk-dropdown li:hover {
  background-color: #f9f9fc;
}

.uk-dropdown li:hover a {
  color: #333333;
}

.uk-dropdown a {
  font-size: 13px;
  color: #666;
  text-transform: uppercase;
}

.uk-dropdown a:hover {
  color: #FFFFFF;
  text-decoration: none;
}

header .logo {
  background-image: url("./../images/logo_round.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 4.5rem;
  height: 4.5rem;
}

header .uk-navbar-nav > li > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

header .menu--top {
  height: 8rem;
}

header .button-user {
  margin-top: 20px;
}

.user-for-phone {
  display: none;
}

.uk-button {
  border-radius: 4px;
}

.uk-button-secondary {
  background-color: #007791;
}

.uk-button-secondary:hover {
  background-color: #007791;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.container--sidebar {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
}

.container--sidebar .uk-nav-primary > li > a {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.uk-legend {
  font-size: 20px;
  color: #007791;
  font-weight: 700;
  margin-top: 40px;
}

.uk-input {
  background-color: transparent;
  margin-top: 5px;
  border: 0px;
  border-bottom: 1px solid #CECECE;
  font-size: 15px;
}

.uk-button-primary {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #4b476d;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 700;
}

.uk-button-primary:hover {
  background-color: #4b476d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.remove_field {
  float: right;
  line-height: 22px;
  margin-bottom: 10px;
}

form label {
  margin-top: 14px !important;
  font-size: 14px !important;
  line-height: 23px;
  color: #565d67;
}

.page--top {
  margin-top: 80px;
  margin-bottom: 30px;
}

.page--content {
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  color: #333;
  padding: 40px;
  margin-top: 30px;
  margin-bottom: 20px !important;
}

table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: unset;
}

table.dataTable tbody tr {
  background-color: unset;
}

table.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 16px 12px;
  vertical-align: top;
}

table.dataTable thead th {
  border-bottom: 0px solid #111;
  padding: 16px 12px;
}

.dataTables_length,
.dataTables_filter {
  margin: 30px 0;
}

.dataTables_filter input {
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  color: #666;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transition-property: color, background-color, border;
  transition-property: color, background-color, border;
  background-color: transparent;
  margin-top: 5px;
  border: 0px;
  border-bottom: 1px solid #CECECE;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
}

.dataTables_length {
  display: none;
}

.dataTables_info,
.dataTables_paginate {
  margin-top: 20px;
  margin-bottom: 30px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: none;
  border: 0;
  outline: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: none;
  border: 0;
  color: #4b476d;
  outline: none;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

table i {
  margin: 0 10px;
  color: #1e87f0;
  color: #007791;
}

.uk-label {
  background-color: #007791;
}

.uk-table td {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.uk-table td.small {
  font-size: 14px;
  line-height: 28px;
  font-weight: 200;
}

.uk-label-danger {
  background-color: #f0506e !important;
}

.paging_simple_numbers span a.current {
  color: #e91e63 !important;
  font-weight: 700;
}

td.uk-align-right {
  margin-bottom: 0 !important;
}

.label {
  background-color: #4b476d;
}

#login h3 {
  margin-left: 0 !important;
}

#login .header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-items: center;
  justify-content: center;
}

#login .header a:hover {
  text-decoration: none;
}

#login .header .logo {
  background-image: url("./../images/logo_round.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 4.5rem;
  height: 4.5rem;
}

#login .header .label {
  background-color: transparent;
  text-transform: uppercase;
  color: #333;
  padding: 0 15px;
}

#login .login-form {
  margin-top: 70px;
}

#login .login-container {
  width: 500px;
  padding-top: 3%;
}

#login .uk-form-horizontal .uk-form-label {
  width: 100px;
}

#login .uk-form-horizontal .uk-form-controls {
  margin-left: 120px;
}

#login form {
  margin-top: 30px;
}

#login .uk-button {
  margin-top: 30px;
}

#login .uk-alert {
  margin: 20px 0;
}

#login .left-box {
  background-color: #f2f0ee;
  padding-top: 3%;
}

#login .right-box {
  background: #f8f9fb;
}

#login .shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#login h3:before {
  content: none !important;
}

.card-link:hover div {
  background-color: #4b476d;
  color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-link:hover h3 {
  color: #FFFFFF;
}

.card-link:hover h3:before {
  color: #FFFFFF;
}

body.home .page--content {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

section.home {
  margin-top: 50px;
}

section.home h3:before {
  color: #96261b;
  content: "#";
  font-weight: 400;
  margin-left: -26px;
  position: absolute;
  font-size: 37px;
  opacity: 0.6;
  margin-top: -9px;
}

section.home a:hover {
  text-decoration: none;
}

body.cards .page--content {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

section.cards {
  margin-top: 50px;
}

section.cards h3:before {
  color: #96261b;
  content: "#";
  font-weight: 400;
  margin-left: -26px;
  position: absolute;
  font-size: 37px;
  opacity: 0.6;
  margin-top: -9px;
}

section.cards a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .page--top--title h2 {
    margin-left: 20px;
    padding: 20px 0;
  }

  .page--top {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .page--top h3 {
    margin-left: 45px;
  }
}

@media only screen and (max-width: 960px) {
  .page--content {
    padding: 10px;
  }

  .page--top h3 {
    font-size: 18px;
  }

  #login .uk-grid {
    margin-left: 0;
  }

  #login .uk-padding {
    padding: 10px;
  }

  #login label {
    text-align: left !important;
  }

  #login .uk-form-horizontal .uk-form-controls {
    margin-left: 0;
  }
}

@media only screen and (max-width: 420px) {
  #login .uk-form-horizontal .uk-form-label {
    width: unset;
  }

  #login .logo {
    width: 3.5rem;
    height: 3.5rem;
  }

  #login .login-form {
    padding: 10px;
  }

  #login h3 {
    margin-left: 0;
  }

  header .logo {
    width: 3.5rem;
    height: 3.5rem;
  }

  header .menu--top {
    height: auto;
  }

  .uk-margin-top {
    margin-top: 0px !important;
  }

  .user-for-phone {
    margin-bottom: 20px;
    display: block;
    display: none;
  }

  .user-for-non-phone {
    display: none;
  }

  .page--top .uk-width-1-2 {
    width: 100%;
  }

  .page--top--title h2 {
    margin-left: 10px;
    font-size: 20px;
  }

  table.mobile-data td {
    display: inline;
  }

  table.mobile-data td:first-child {
    display: block !important;
    font-weight: bold;
  }

  table.mobile-data tr {
    border-top: 1px solid #e5e5e5;
    display: block;
    padding: 7px 0;
  }

  table.mobile-data thead {
    display: none;
  }

  table.mobile-data td.small {
    font-weight: 500;
    line-height: 34px;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 0;
  }

  .introduction {
    font-weight: 500;
  }
}

@media only screen and (max-width: 375px) {
  header .logo,
  #login .logo {
    width: 3rem !important;
    height: 3rem !important;
  }

  a.uk-navbar-toggle {
    padding: 0;
  }
}

