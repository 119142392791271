$color-primary: #e91e63;
//$color-secondary: #2e8cf1;
$color-secondary: #4b476d;

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: #f9f9fc;
  min-height: 100vh;
  color: #666;
}

.uk-tooltip {
  font-size: 14px;
  font-weight: 200;
  transition: background 0.05s ease;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0,0,0,.15);
}

.mini-select {
  float: right;
  width: 80px;
}

//menu

//[class*=uk-dropdown-bottom] {
//    margin-top: 10px;
//}

