@media only screen and (max-width : 1200px) {

    .page--top--title {
        h2 {
            margin-left: 20px;
            padding: 20px 0;
        }
    }

    .page--top {
        margin-top: 50px;
        margin-bottom: 10px;

        h3 {
            margin-left: 45px;
        }
    }


}

@media only screen and (max-width : 960px) {
    .page--content {
        padding: 10px;
    }

    .page--top {
        h3 {
            font-size: 18px;
        }
    }

    #login {
        .uk-grid {
            margin-left: 0;
        }

        .uk-padding {
            padding: 10px;
        }

        label {
            text-align: left !important;
        }

        .uk-form-horizontal .uk-form-controls {
            margin-left: 0;
        }


    }
}

@media only screen and (max-width : 420px) {

    #login {
        .uk-form-horizontal .uk-form-label {
            width: unset;
        }



        .logo {
            width: 3.5rem;
            height: 3.5rem;
        }

        .login-form {
            padding: 10px;
        }

        h3 {
            margin-left: 0;
        }
    }


    header {
        .logo {
            width: 3.5rem;
            height: 3.5rem;
        }

        .menu--top {
            height: auto;
        }

    }

    .uk-margin-top {
        margin-top: 0px!important;
    }

    .user-for-phone {
        margin-bottom: 20px;
        display: block;
        display: none;
    }

    .user-for-non-phone {
        display: none;
    }

    .page--top {
        .uk-width-1-2 {
            width: 100%;
        }
    }

    .page--top--title {
        h2 {
            margin-left: 10px;
            font-size: 20px;
        }
    }

    table.mobile-data {
        td {
            display: inline;
        }

        td:first-child {
            display: block !important;
            font-weight: bold;
        }

        tr {
            border-top: 1px solid #e5e5e5;
            display: block;
            padding: 7px 0;
        }

        thead {
            display: none;
        }

        td.small {
            font-weight: 500;
            line-height: 34px;
        }
    }

    table.dataTable tbody th, table.dataTable tbody td {
        padding: 0;
    }

    .introduction {
        font-weight: 500;
    }

}

@media only screen and (max-width : 375px) {
    header, #login {
        .logo {
            width: 3.0rem !important;
            height: 3.0rem !important;
        }
    }

    a.uk-navbar-toggle {
        padding: 0;
    }

}
