header {
  //box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15) !important;
  //margin-bottom: 3rem;

  .logo {
    background-image: url("../../images/logo_round.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 4.5rem;
    height: 4.5rem;
  }

  .uk-navbar-nav>li.uk-active>a {

}

.uk-navbar-nav>li>a {

}

  .uk-navbar-nav>li>a {

    //padding: 10px;
    //min-height: auto;
    //text-transform: none;
    //margin: 0 4px;

    &:hover {
      background-color: rgba(255,255,255,.1);

      border-radius: 6px;
    }
  }

  .menu--top {
    height: 8rem;
    //background: rgb(150,38,27);
    //background: linear-gradient(128deg, rgba(150,38,27,1) 0%, rgba(18,56,127,1) 36%);
  }

  .button-user {
    margin-top: 20px;
  }

}

.user-for-phone {
    display: none;
}
