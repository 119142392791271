.page--top {
  margin-top: 80px;
  margin-bottom: 30px;
}

.page--content {
    background: #FFFFFF;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
    color: #333;
    padding: 40px;
    margin-top: 30px;
    margin-bottom: 20px !important;
}
