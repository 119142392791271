.uk-dropdown {
    padding: 5px;

    li {
        padding: 3px;
        padding-left: 10px;

        &:hover {
            background-color: #f9f9fc;

            a {
                color: #333333;
            }

        }
    }

    a {
        font-size: 13px;
        color: #666;

        text-transform: uppercase;
        &:hover {
            color: #FFFFFF;
            text-decoration: none;

        }
    }
}
