.introduction {
    font-size: 14px;
    line-height: 30px;
    font-weight: 200;
    color: #283b80;
}

.question {
    font-size: 17px;
    line-height: 26px;
}

.mark {
    color: $color-primary;
    font-weight: 400;
    text-transform: lowercase;
}

.answer-row {
    border-bottom: 1px solid #e6e6e6;
    padding: 30px 0;

    label {
        font-size: 20px;
    }

}

.text-large {
    font-size: 17px !important;
    line-height: 24px;
    font-weight: 200;
    margin-top: 14px !important;
}

.text-regular {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
}

.text-normal {
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
}

.text-bold {
    font-size: 15px;
    line-height: 30px;
    font-weight: 700;
}

.text-correct {
    color:green;
}

.text-incorrect {
    color: #FF0000;
}
