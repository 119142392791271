// reset datatables css
table.dataTable thead th, table.dataTable tfoot th {
  font-weight: unset;
}

table.dataTable tbody tr {
  background-color: unset;
}

table.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 16px 12px;
  vertical-align: top;
}

table.dataTable thead th {
  border-bottom: 0px solid #111;
  padding: 16px 12px;
}

.dataTables_length, .dataTables_filter {
  margin: 30px 0;
}

.dataTables_filter {
  input {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
    color: #666;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    -webkit-transition-property: color,background-color,border;
    transition-property: color,background-color,border;
    background-color: transparent;
    margin-top: 5px;
    border: 0px;
    border-bottom: 1px solid #CECECE;
    height: 40px;
    vertical-align: middle;
    display: inline-block;
  }
}

.dataTables_length {
  display: none;
}

.dataTables_info, .dataTables_paginate {
  margin-top: 20px;
  margin-bottom: 30px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: none;
  border: 0;
  outline: none;

  &:hover {
    background: none;
    border: 0;
    color: $color-secondary;
    outline: none;
  }

}

table.dataTable.no-footer {
  border-bottom: 0;
}

// end reset datatables css


table {
  i {
    margin: 0 10px;
    color: #1e87f0;
    color: #007791;

    &:hover {

    }
  }

}

.uk-label {
  background-color: #007791;
}

.uk-table td {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.uk-table td.small {
  font-size: 14px;
  line-height: 28px;
  font-weight: 200;
}

.uk-label-danger {
  background-color: #f0506e !important;
}

.paging_simple_numbers {

  span {
    a.current {
      color: $color-primary !important;
      font-weight: 700;
    }
  }

}

td.uk-align-right {
    margin-bottom: 0 !important;
}
