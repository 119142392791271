#login {

  h3 {
        margin-left: 0 !important;
  }

  .header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    align-items: center;
    justify-content: center;

    a:hover {
          text-decoration: none;
    }

    .logo {
      background-image: url("../../images/logo_round.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 4.5rem;
      height: 4.5rem;
    }



    .label {
      background-color: transparent;
      text-transform: uppercase;
      color: #333;
      padding: 0 15px;
    }

  }

  .login-form {
    margin-top: 70px;

  }

  .login-container {
    width: 500px;
      padding-top: 3%;
  }

  .uk-form-horizontal .uk-form-label {
    width: 100px;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 120px;
  }
  form {
    margin-top: 30px;

  }

    .uk-button {
        margin-top: 30px;

    }

  .uk-alert {
    margin: 20px 0;
  }

  .left-box {
      background-color: #f2f0ee;
      padding-top: 3%;
  }

  .right-box {
      background: #f8f9fb;
  }

  .shadow {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  h3:before {
        content: none !important;
  }

}
