h2 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 400;
    color: #FFF;
    padding: 30px 0;

}

h3 {
    margin: 0;
    color: #090910;
    margin-left: 20px;
    line-height: 26px;
    font-weight: 700;
    font-size: 24px;
    //color: #0f1622;

    &:before {
        color: $color-primary;
        content: "#";
        font-weight: 400;
        margin-left: -38px;
        position: absolute;
        font-size: 44px;
        opacity: 0.6;
    }

}

h4 {
    margin: 0;
    color: #090910;
    line-height: 28px;
    font-weight: 700;
    font-size: 22px;
}
