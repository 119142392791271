.container--sidebar {
  @include i-container;

  .uk-nav-primary>li>a {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

}
