.uk-legend {
    font-size: 20px;
    color: #007791;
    font-weight: 700;
    margin-top: 40px;
}

.uk-input {
  background-color: transparent;
  margin-top: 5px;
  border: 0px;
  border-bottom: 1px solid #CECECE;
  font-size: 15px;
}

.uk-button-primary {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: $color-secondary;
  box-shadow: 0 5px 15px rgba(0,0,0,.08);
  transition: all .3s ease;
  font-size: 14px;
  font-weight: 700;


  &:hover {
    background-color: $color-secondary;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.remove_field {
  float: right;
  line-height: 22px;
  margin-bottom: 10px;
}

form {
  label {
    //font-size: 20px !important;
    //line-height: 30px;
    //font-weight: 200;
   margin-top: 14px !important;
   font-size: 14px !important;
   line-height: 23px;
   color:#565d67;

  }
}
