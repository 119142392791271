body.cards {
    .page--content {
        @include section-page-content;
    }
}

section.cards {
    @include section-global;
}



