.uk-button {
  border-radius: 4px;
}

.uk-button-secondary {
    background-color: #007791;

    &:hover {
        background-color: #007791;
        box-shadow: 0 5px 12px rgba(0,0,0,.15);
    }
}
