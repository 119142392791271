@mixin section-page-content {
    background: transparent;
    box-shadow: none;
    padding: 0;
}

@mixin section-global {
    margin-top: 50px;

    h3:before {
        color: #96261b;
        content: "#";
        font-weight: 400;
        margin-left: -26px;
        position: absolute;
        font-size: 37px;
        opacity: 0.6;
        margin-top: -9px;
    }

    a:hover {
        text-decoration: none;
    }
}

@mixin section-card-link {
    &:hover {
        div {
            background-color: $color-secondary;
            color: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        h3 {
            color: #FFFFFF;
        }

        h3:before {
            color: #FFFFFF;
        }

    }
}
